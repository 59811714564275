<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="chat_header">
          <div class="inner">
            <span>위즈톡</span>
            <button class="btn_close" @click="fnBack"></button>
          </div>
        </div>
        <div class="chat_nav">
          <div class="inner">
            <div>
              <div class="ttl">{{ title }}</div>
              <span class="name">{{ nick }}</span> 님과의 대화
            </div>
          </div>
        </div>
        <div class="chat_main">
          <div class="caution">
            <div class="inner">
              <dl>
                <dd>
                  <div>* 파티장과 파티원의 1:1 대화방입니다.</div>
                  <div>* 파티 공지/ 문의 외 다른 용도로 이용을 금합니다.</div>
                  <div>
                    * 위즈니 서비스 외 별도의 결제방식이나 금품등을 요구할 수
                    없습니다.
                  </div>
                  <div>
                    * 대화시 비속어 또는 욕설, 사회적 통념에 어긋나는 대화를
                    통해 상대방을 불쾌하게 하시는 경우 서비스 이용 제제 및 형사
                    처벌 대상이 될수 있습니다. 건전한 인터넷 문화 조성에 동참해
                    주세요.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="chat_list">
            <ul class="chat_list_ul">
              <li class="chat_list_li warning">
                <div class="chat_cont warning">
                  <div class="chat_txt">
                    <img
                      src="@/assets/static/images/warning_img.svg"
                      style="width: 2rem;"
                    />
                    <div class="txt">
                      최근 개인 거래 유도로 인해 <span>사기 피해</span>가
                      많아지고 있습니다. 개인 거래로 인한 피해를 입지 않도록
                      주의해 주세요.
                    </div>
                  </div>
                </div>
              </li>
              <li
                class="chat_list_li"
                :class="{
                  you: item.position === 'align-l'
                }"
                v-for="(item, index) in list"
                :key="index"
              >
                <!--'상대 대화: you'-->
                <div class="chat_cont">
                  <div class="chat_txt">
                    <span>{{ item.message }}</span>
                  </div>
                  <p class="chat_time">
                    {{ $dayjs(item.sendDt).format('YYYY.MM.DD') }}<br />
                    {{ $dayjs(item.sendDt).format('A hh:mm') }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="chat_input">
          <div class="inner">
            <label class="btn_wrap">
              <input
                id="sendMessage"
                type="text"
                v-model="text"
                @keyup.enter="fnWsSendMessage"
              />
              <input
                class="btn"
                type="submit"
                onsubmit="return false"
                value="전송"
                @click="fnWsSendMessage"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'market-wizz-talk-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
