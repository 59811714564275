import {
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  onBeforeUnmount,
  computed
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  connect,
  sendMessage,
  disconnect
} from '@/assets/static/js/wizzneyWebSocket.js'
import { fnModal } from '@/components/common/modal'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    params: {
      marketNo: route.query.marketNo,
      memberSeq: route.query.memberSeq,
      buyerChatroomId: route.query.chatroomId,
      sellerChatroomId: '',
      type: route.query.type
    },
    list: [],
    text: '',
    nick: '',
    title: '',
    memberNo: '',
    isSeller: false // 판매자여부(true : 판매자, false: 구매자)
  })
  const fnChatroomId = async () => {
    const res = await proxy.$MarketSvc.getChatroomId({
      marketNo: state.params.marketNo,
      memberSeq: state.params.memberSeq,
      type: state.params.type
    })
    if (res.resultCode === '0000') {
      const chatroomId = res.chatroomId
      if (chatroomId !== state.params.buyerChatroomId) {
        state.params.sellerChatroomId = chatroomId
        state.isSeller = true
      } else {
        state.isSeller = false
      }
    }
  }
  const fnList = async () => {
    const res = await proxy.$MarketSvc.postChatList(state.params)
    state.list = state.list.concat(res.chatList)
    state.nick = res.nick
    state.title = res.title
    state.memberNo = res.memberNo
    fnScrollBottom()
  }
  const fnWsConnect = async () => {
    const type = 'market'
    const marketNo = route.query.marketNo
    const chatroomId = route.query.chatroomId
    connect(type, marketNo, chatroomId, resultJson => {
      if (state.isSeller) {
        if (resultJson.position === 'align-l') {
          resultJson.position = 'align-r'
        } else {
          resultJson.position = 'align-l'
        }
      }
      state.list.push(resultJson)
      fnScrollBottom()
    })
  }
  const fnWsSendMessage = () => {
    if (proxy.$Util.isEmpty(state.text)) return
    const params = { ...state.params }
    params.message = state.text
    let sendUrl = '/app/chat.message/toMarketSeller'
    if (state.isSeller) {
      sendUrl = '/app/chat.message/toMarketBuyer'
    }
    sendMessage(sendUrl, params)
    state.text = ''
    document.getElementById('sendMessage').focus()
  }
  const fnScrollBottom = () => {
    const dom = document.querySelector('.chat_input')
    setTimeout(() => {
      dom.scrollIntoView()
    }, 100)
  }
  const fnBack = async () => {
    await router.go(-1)
  }
  onMounted(async () => {
    // 채팅 읽음처리
    await proxy.$MarketSvc.postChangeChatRead(route.query)
  })
  onBeforeUnmount(() => {
    disconnect()
  })
  const init = async () => {
    await fnWsConnect()
    await fnChatroomId()
    await fnList()
    if (proxy.$Util.isMobile()) {
      const isNotShowWizzTalkInfo = getters['wizzTalk/getIsNotShowWizzTalkInfo']
      const isShowById = proxy.$_.find(isNotShowWizzTalkInfo, {
        id: state.userData.userId || state.userData.snsId
      })
      if (
        proxy.$Util.isEmpty(isShowById) ||
        (!proxy.$Util.isEmpty(isShowById) &&
          isShowById.value === proxy.$ConstCode.BOOLEAN_VALUE.FALSE)
      ) {
        const params = {
          component: proxy.$modalNames.WIZZ_TALK_INFO,
          data: {},
          callback: () => {
            fnScrollBottom()
          }
        }
        await fnModal(params)
      }
    }
  }
  init()
  return { ...toRefs(state), fnList, fnWsConnect, fnWsSendMessage, fnBack }
}
